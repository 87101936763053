<template>
  <a-modal v-model="modelShow" title class="engineering-model" @cancel="cancel" :footer="null" centered>
    <div class="close-icon" slot="closeIcon" title="关闭">
      <i class="iconfont icon-danchuangguanbi-anniu"></i>
    </div>
    <div class="engineering-content">
      <div class="operation">
        <div class="total">共获取 {{pager.count}} 条数据</div>
      </div>
      <div class="table-content">
        <a-spin :spinning="tableLoading" size="large">
          <el-table :data="tableData" border style="width: 100%" :header-cell-style="{fontSize: '14px',color:'#000'}">
            <el-table-column prop="PROJECT_CODE" label="工程编码">
            </el-table-column>
            <el-table-column prop="CUSTOMER_NAME" label="工程使用单位">
            </el-table-column>
            <el-table-column prop="CUSTOMER_ADDR" label="使用单位地址">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <span style="color:#1890FF;font-size: 14px;cursor: pointer;" @click="handleClick(scope.row)">添加</span>
              </template>
            </el-table-column>
          </el-table>
        </a-spin>
        <div class="footer-pager">
          <Pagination :pager="pager" @query="getTableData" />
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { engineeringsCol } from '../config'
import { getEngineerlist } from '../api'

const initializeConfig = {
  pager: {
    count: "0",
    pageNo: 1,
    pageSize: 10
  },
}

export default {
  name: "engineeringModal",
  props: {
    modelShow: {
      type: Boolean,
      required: true
    },
    dataSource: {
      type: Object,
      required: true
    },// 物料组id 代理商id
  },
  model: {
    prop: "modelShow",
    event: "input"
  },
  data() {
    return {
      tableData: [],
      engineeringsCol: Object.freeze(engineeringsCol),
      pager: { ...initializeConfig.pager },
      tableLoading: false,
      expLoading: false,
    }
  },
  watch: {
    modelShow: {
      handler: function (val) {
      },
      immediate: true
    }
  },
  created() {
    // this.init()
  },
  methods: {
    init() {

    },
    // 关闭弹窗
    cancel() {
      this.$emit("input", false);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleClick(info) {
      this.$emit("addProject", info);
    },
    getEngineerListFunc() {
      let body = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        matklId: this.dataSource.matklId,
        custId: this.dataSource.custId
      }
      this.getEngineerList(body)
    },
    // 查询工程项目数据
    async getEngineerList(data) {
      try {
        const engineerListRes = await getEngineerlist(data)
        console.log('工程项目列表', engineerListRes)
        if (engineerListRes.data.code === 0) {
          this.tableData = engineerListRes.data.list
          this.pager.count = engineerListRes.data.totalRows 
        }
      } catch (error) {
      }
    },
    getTableData(page,pageSize) {
      this.pager = {
        ...this.pager,
        pageNo: page,
        pageSize: pageSize || this.pager.pageSize
      }
      this.getEngineerListFunc()
    }
  }
}
</script>
<style lang="less" scope>
.engineering-model {
  .engineering-content {
    max-height: 662px;
    overflow: scroll;
    padding-right: 10px;



    .operation {
      margin-bottom: 12px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .table-content {
      .footer-pager {
        margin-top: 8px;
        margin-right: -10px;
      }
    }

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 4px;
      box-shadow: 0px 1px 3px #E8E8E8 inset;
      background: #E8E8E8;
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: 0px 1px 3px #fff inset;
      border-radius: 4px;
      background: #fff;
    }
  }

  .ant-modal-body {
    padding: 34px 4px 11px 24px;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-close-x {
    width: 42px;
    height: 42px;
    border-top-right-radius: 10px;
    line-height: 42px;

    .close-icon {
      width: 42px;
      height: 42px;
      text-align: center;
      line-height: 42px;

      i {
        //color: #00aaa6;
        font-size: 16px;
      }
    }

    &:hover {
      background: #e4f8f7;

      i {
        color: #00aaa6;
      }
    }
  }




  .ant-modal {
    width: 803px !important;
    max-height: 662px;
    margin: 0 auto !important;

    .ant-modal-title {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #262626 !important;
      line-height: 20px !important;
    }

    .ant-modal-body .ant-table-column-title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .count-span {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    text-align: left;
    margin-top: 17px;
    float: left;
    margin-bottom: 12px
  }

  .btnGroup {
    float: left;
    width: 150px;
    margin-top: 17px;
    margin-bottom: 12px;
    margin-left: 24px
  }

  .page-check-wrap {
    padding-top: 8px;
    padding-bottom: 6px;

    .check-wrap {
      float: left;
      padding-top: 12px;
    }

    .footer-pager {
      float: right;
    }
  }

  .ant-table-tbody>tr>td {
    padding: 8px 16px;
  }
}

.el-table {
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset;
    border-radius: 4px;
    background-color: #fff;
  }

  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #E8E8E8 inset;
    border-radius: 4px;
    background-color: #E8E8E8;
  }
}
</style>