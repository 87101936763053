import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

//  根据商家查询对应的合作的空调物料组
export function selectKtMatkl() {
  return http({
    url: "/fxEngineering/selectKtMatkl.nd",
    method: "get",
  });
}
//  根据物料组查询供应商
export function findDlCust(id) {
  return http({
    url: "/fxEngineering/findDlCust.nd?matklId=" + id,
    method: "get",
  });
}

// 查询入库仓库
export function getInboundWarehouse(data) {
  return dmshttp({
    url: "findBaseData",
    data,
  });
}

// 根据供应商代理商查询工程项目
export function getEngineerlist(data) {
  return http({
    url: "/fxEngineering/queryEngineer.nd",
    method: "post",
    data
  });
}

// 根据工程项目查询产品信息
export function getProductListByEngineeringCode(data) {
  return dmshttp({
    url: "findProductListByEngineeringCode",
    data,
  });
}