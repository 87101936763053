import {
  breadcrumbData,
  sexoptions,
  formatDate,
  isFreeShipping,
} from "./config";
import {
  findList,
  findListLabel,
  saveInfo,
  saveInfoLabel,
} from "@/views/userOperations/IntendedUserEntry/api";
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import engineeringModal from "./components/engineeringModal";
import {
  selectKtMatkl,
  findDlCust,
  getInboundWarehouse,
  getProductListByEngineeringCode,
} from "./api";
import {
  ctsApi,
  fxZsyhGetDeliveryMethod,
  orderSubmit2,
  getAddress,
  orderSubmit2C,
  getSupplierS,
  getPhone,
} from "@/views/channel/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import { getAdressapi } from "@/views/settlement/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";

export default {
  name: "engineeringPrcment",
  components: {
    addUserSource,
    addUserTips,
    engineeringModal,
  },
  data() {
    return {
      breadcrumbData: Object.freeze(breadcrumbData),
      deliveryMethodOptions: [], // 配送方式
      sexoptions: Object.freeze(sexoptions), // 性别
      visibleaA: false, // 用户来源弹窗
      userSorceList: [], // 用户来源数据
      SorceIds: "", // 选中的用户来源
      visibleTips: false, // 用户标签弹窗
      userTipsList: [], // 用户标签数据
      TipsIds: [], // 选中的标签id
      TipsNames: [], // 选中的标签name
      pageLoadFlag: false, // 页面loading
      productLoadFlag: false, // 产品模块loading
      engineeringModalVisible: false, // 选择工程弹窗
      giveType: undefined, // 当前配送方式
      addressHx: [], // 收货地址省市区code数组
      userDetailAddress: "", // 收货详细地址
      companyList: [], // 收货地址自动完成地址
      fetching: false, // 收货地址自动完成loading
      matklOption: [], // 物料组option
      dlCustOption: [], //  供应商option
      warehouseOption: [], // 入库仓库option
      contactOption: [], // 联系人option
      phoneOption: [], // 手机号option
      contactList: [], // 收货人联系人信息数组
      matklId: "", // 物料组id
      matklCode: "", // 物料组code
      custId: undefined, // 供应商id
      dlCustCisCode: "", // 供应商cisCode
      dlCustSelectOpen: false, // 供应商select面板
      userid: "", // 采购人员id
      documentdata: formatDate(Date.parse(new Date()), "Y-M-D"),
      submitFormData: {
        purchaseOrder: {
          supplierCode: "", // 代理商id
          warehouseId: undefined, // 入库仓库
          shippingAddress: {
            key: "", //  完整收货地址全名
            value: undefined, // 完整收货地址id
          },
          storeCode: "", // 销量所属门店
          contact: "", // 联系人
          contactInfo: "", // 联系方式
          gender: 1, // 性别 默认男
          message: "", // 订单备注
        },
      },
      productList: [], // 产品数组
      productAllInfo: {
        totalAmount: 0, // 总价
        totalNum: 0, // 总台数
        totalVolume: 0, // 总体积
      },
      shopListOption: [], // 销量所属门店列表
      engineeringInfo: null, // 当前所选的工程项目信息
      engineeringText: undefined, // 当前所选工程项目文本
      shippingAddressOption: [], // 下拉的完整收货地址
      totalFwfAmount: 0, // 总服务费
      haveDeliveryFee: "0", // 0没有服务费 1有服务费
      startTime: "", // 期望送达时间
      startShowTime: "", //期望送达时间 tip展示时间
      endOpen: false, // 期望送达时间是否打开选择器面板
    };
  },
  watch: {
    productList: {
      handler: function(val) {
        console.log("商品列表watch", val);
        let orderNum = 0; // 商品总数;
        let orderVolume = 0; // 商品总体积
        let orderAmount = 0; // 商品总价

        val.forEach((el) => {
          if (el.productOrderNum) {
            orderNum += el.productOrderNum;
            // 单个商品体积
            let tempVolume = Number(
              (Number(el.productOrderNum) * Number(el.volume)).toFixed(3)
            );
            orderVolume += tempVolume;
            // 单个商品总价
            let tempAmount =  Number((
              Number(el.productOrderNum) * Number(el.price)
            ).toFixed(2));
            orderAmount += tempAmount;
          }
        });
        // 商品数量大于等于5 展示直配工地
        let temp = this.deliveryMethodOptions;
        const shouldShow = orderNum >= 5;
        // 如果当前
        if(!shouldShow && this.giveType === '07'){
          this.giveType = '02'
        }
        temp.forEach((el) => {
          if (el.code === "07") {
            el.isShow = shouldShow;
          }
        });
        this.deliveryMethodOptions = temp;
        console.log("商品列表总数", orderNum);
        // 计算商品总台数以及价格总计
        this.productAllInfo.totalNum = orderNum;
        this.productAllInfo.totalVolume = orderVolume.toFixed(3);
        this.productAllInfo.totalAmount = orderAmount;
      },
      deep: true,
    },
    // 监听完整收货地址变化
    "submitFormData.purchaseOrder.shippingAddress.value": {
      handler: function(val) {
        this.getContactInfo();
      },
    },
    // 监听手机号
    "submitFormData.purchaseOrder.contactInfo": {
      handler: function(val) {
        let re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
        let result = re.test(val);
        if (!result) {
          return;
        }
        this.getUserByPhoneOrShop();
      },
    },
    // 监听销量所属门店
    "submitFormData.purchaseOrder.storeCode": {
      handler: function(val) {
        if (val) {
          this.getUserByPhoneOrShop();
        }
      },
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  beforeCreate() {},

  methods: {
    init() {
      let promises = [
        this.getuserSorceList(),
        this.getuserTipList(),
        this.getDeliveryMethod(), // 获取配送方式
        this.getMatklOption(), //  获取物料组数据
        this.getInboundWarehouseOption(), //  获取入库仓库数据
        this.getShopList(), // 获取销量所述门店
        this.cglrywy(), // 获取采购人员id
      ];
      this.pageLoadFlag = true;
      Promise.all(promises)
        .then((results) => {})
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 省市区选择器change
    onAddressChange(value) {
      console.log("省市区选择器change", value);
      this.addressHx = value;
      // 查一次服务费
      this.getFWF();
    },
    // 智能识别地址
    recognitionFun(addressInfo) {
      console.log("智能识别地址", addressInfo);
      const town_code = addressInfo.levelAddress.town_code;
      // 省市区code
      const provinceCode = town_code.slice(0, 2).padEnd(12, 0);
      const cityCode = town_code.slice(0, 4).padEnd(12, 0);
      const districtCode = town_code.slice(0, 6).padEnd(12, 0);
      const townCode = town_code.padEnd(12, 0);
      this.addressHx = [provinceCode, cityCode, districtCode, townCode];
      // 详细地址
      this.userDetailAddress = addressInfo.address;
      // 姓名
      this.submitFormData.purchaseOrder.contact = addressInfo.userName;
      // 电话
      this.submitFormData.purchaseOrder.contactInfo = addressInfo.mobile;
      // 查服务费
      this.getFWF();
    },
    // 选择工程项目select聚焦事件
    engineeringProjFocus() {
      if (!this.matklId) {
        this.$message.warning("请先选择物料组");
        return;
      }
      if (!this.custId) {
        this.$message.warning("请先选择供应商");
        return;
      }

      this.engineeringModalVisible = true;
      this.$refs.engineeringProjSelectRef.blur();
    },
    // 选择工程项目
    addProject(info) {
      console.log("选择工程项目", info);
      this.engineeringInfo = info;
      this.engineeringText = info.PROJECT_CODE + "-" + info.CUSTOMER_NAME;
      let code = info.PROJECT_CODE;
      this.getProductList(code); // 获取产品信息数组
      this.getAddressList(); // 获取下拉的完整收货地址
      this.engineeringModalVisible = false;
    },
    // 选择配送方式
    onGiveType(e) {
      console.log("配送方式", e);
      const giveType = e.target.value;
      // this.giveType = giveType;
      // 直送用户 送装一体
      if (["04", "08"].includes(giveType)) {
        this.haveDeliveryFee = "1";
        // 查服务费
        this.getFWF();
      } else {
        this.haveDeliveryFee = "0";
      }
    },
    // 获取用户来源
    getuserSorceList() {
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
      };
      return findList(data).then((res) => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if (this.userSorceList.length > 0) {
            this.userSorceList.forEach((item) => {
              item.id = item.id.toString();
              item.ischerk = false;
            });
          }
        }
      });
    },
    // 获取用户标签
    getuserTipList() {
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
      };
      return findListLabel(data).then((res) => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach((item) => {
              item.checked = false;
            });
          }
        }
      });
    },
    // 用户来源弹窗打开
    openSource() {
      this.visibleaA = true;
    },
    // 点击用户来源标签
    changeSorceBtn(item) {
      if (this.SorceIds == item.id) {
        this.SorceIds = "";
        return;
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    // 用户来源change
    changeBtnnew(item) {
      this.SorceIds = item;
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value, value2) {
      console.log("用户来源点击确定", value, value2);
      if (!value) {
        this.SorceIds = value2;
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: "",
      };
      this.pageLoadFlag = true;
      saveInfo(data).then((res) => {
        if (res.data.code == 0) {
          this.userSorceList.push({
            id: res.data.data,
            source: value,
          });
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    // 用户标签弹窗打开
    openTips() {
      this.visibleTips = true;
    },
    // 点击用户标签标签
    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.TipsNames = [];
      this.userTipsList.forEach((items) => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
          this.TipsNames.push(items.label);
        }
      });
      this.$forceUpdate();
    },
    // 用户标签点击确定 调接口 增加数据 成功的话赋值
    getReasonTip(value, value1) {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1,
      };
      saveInfoLabel(data).then((res) => {
        if (res.data.code == 0) {
          this.userTipsList.push({
            id: res.data.data,
            label: value,
            checked: true,
          });
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    // 用户标签点击弹窗取消和确定
    chengeOK(item, name) {
      console.log(" 用户标签点击弹窗取消和确定", item, name);
      this.TipsIds = [];
      this.TipsNames = [];
      this.TipsIds = item;
      this.TipsNames = name;
      this.userTipsList.forEach((items) => {
        items.checked = false;
        item.forEach((itemB) => {
          if (items.id == itemB) {
            items.checked = true;
          }
        });
      });
    },
    // 获取配送方式
    getDeliveryMethod() {
      let items = [];
      let productIds = [];
      if (this.productList.length > 0) {
        this.productList.forEach((el) => {
          productIds.push({
            id: el.id,
          });
        });
        items = [
          {
            orgId: this.engineeringInfo.ORG_ID,
            matklCode: this.matklCode,
            productIds: productIds,
          },
        ];
      }
      let data = {
        orderType: 2,
        fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
        dlCustCisCode: this.dlCustCisCode,
        items: items,
      };
      return fxZsyhGetDeliveryMethod(data).then((res) => {
        if (res.data.code == 0) {
          this.deliveryMethodOptions = res.data.data.items.map((el) => {
            let isShow = true;
            // 默认不展示支配工地
            if (el.code === "07") {
              isShow = false;
            }
            return {
              ...el,
              isShow,
            };
          });
          console.log("获取配送方式", this.deliveryMethodOptions);
          this.giveType = "02"; // 默认配送 为配送方式
        }
      });
    },
    // 根据商家查询物料组数据
    async getMatklOption() {
      try {
        const matklOptionRes = await selectKtMatkl();
        console.log("物料组option", matklOptionRes.data);

        if (matklOptionRes.data.code === 0) {
          const temp = matklOptionRes.data.data.map((item) => {
            return {
              value: item.id,
              label: item.matklName,
              matklCode: item.matklCode,
            };
          });
          this.matklOption = temp;
        }
      } catch (error) {}
    },
    // 根据物料组查询供应商
    async getDlCustOption(matklId) {
      try {
        console.log("获取供应商option", matklId);
        this.pageLoadFlag = true;
        const dlCustOptionRes = await findDlCust(matklId);
        if (dlCustOptionRes.data.code === 0) {
          const temp = dlCustOptionRes.data.data.map((item) => {
            return {
              value: item.custId,
              label: item.custName,
              mdmCode: item.mdmCode,
              cisCode: item.cisCode,
            };
          });
          console.log("供应商option", dlCustOptionRes);
          this.dlCustOption = temp;
        }
      } catch (error) {
      } finally {
        this.pageLoadFlag = false;
      }
    },
    // 物料组下拉change
    handleMatklChange(value, option) {
      const { matklCode } = option.data.props;
      // 获取供应商
      this.getDlCustOption(value);
      this.matklId = value;
      this.matklCode = matklCode;
      // 重置供应商
      this.custId = undefined;
      this.submitFormData.purchaseOrder.supplierCode = '';
      this.dlCustCisCode = '';
      // 重置工程项目
      this.engineeringText = undefined
    },
    // 是否免运费
    isFreeShipping,
    // 供应商下拉focus
    dlCustFocus() {
      if (!this.matklId) {
        this.$message.warning("请先选择物料组");
        return;
      }
      this.dlCustSelectOpen = true;
    },
    // 供应商下拉blur
    dlCustBlur() {
      this.dlCustSelectOpen = false;
    },
    // 供应商下拉change
    handleDlCustChange(value, option) {
      console.log("供应商mdmcode", option);
      const { mdmCode, cisCode } = option.data.props;
      this.custId = value;
      this.submitFormData.purchaseOrder.supplierCode = mdmCode;
      this.dlCustCisCode = cisCode;
      this.dlCustSelectOpen = false;
      this.$refs.custSelectRef.blur();
      this.$nextTick(() => {
        this.$refs.engineerModal.getEngineerListFunc();
      });
    },
    // 查询入库仓库
    async getInboundWarehouseOption() {
      try {
        let data = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          userAccount: this.$store.state.user.userInfo.account.account,
          type: "cglrrkck",
        };
        let InboundWarehouseOptionRes = await getInboundWarehouse(data);
        InboundWarehouseOptionRes = JSON.parse(InboundWarehouseOptionRes.data);
        console.log("入库仓库option", InboundWarehouseOptionRes.data);
        if (InboundWarehouseOptionRes.code === "0") {
          const temp = InboundWarehouseOptionRes.data.map((item) => {
            let keyValueArr = Object.entries(item);
            return {
              value: keyValueArr[0][0],
              label: keyValueArr[0][1],
            };
          });
          if(temp.length > 0){
            this.submitFormData.purchaseOrder.warehouseId = temp[0].value
          }
          this.warehouseOption = temp;
        }
      } catch (error) {}
    },
    // 根据工程项目查询产品信息
    async getProductList(engineeringCode) {
      try {
        this.pageLoadFlag = true;
        let productListRes = await getProductListByEngineeringCode({
          engineeringCode,
        });
        productListRes = JSON.parse(productListRes.data);
        if (productListRes.code === "0") {
          this.productList = []
          console.log("接口获取的产品信息x", productListRes);
          let productListTemp = productListRes.data;
          for (let product of productListTemp) {
            const checkRetailLRes = await checkRetailL({ id: product.id });
            console.log("查零售价格", checkRetailLRes);
            if (checkRetailLRes.data && checkRetailLRes.data.code == 0) {
              const {
                type,
                money,
                bei,
                min,
                max,
                haveRetailL,
              } = checkRetailLRes.data; // 0不限制  1限制
              product.min = min;
              product.bei = bei;
              product.type = type;
              product.max = max;
              product.salePrice = this.$util.getFloat(Number(money), 2);
              product.retailPrice = this.$util.getFloat(Number(money), 2);
              product.haveRetailL = haveRetailL;
            }
            product.subtotal = 0;
            product.isEdit = false;
            product.productOrderNum = 0;
          }
          console.log("处理后的产品信息", productListTemp);
          this.productList = productListTemp;
          // 再获取一次配送方式
          this.getDeliveryMethod();
        }
      } finally {
        this.pageLoadFlag = false;
      }
    },
    // 商品零售价格编辑
    priceEdit(prodItem) {
      let id = prodItem.id;
      let tempIndex = this.productList.findIndex((el) => el.id === id);
      let tempItem = this.productList.find((el) => el.id === id);
      tempItem.isEdit = !tempItem.isEdit;
      let salePrice = Number(prodItem.salePrice);
      console.log("商品零售价格编辑", prodItem);
      // if (prodItem.idEdit) {
      if (salePrice > prodItem.max || salePrice < prodItem.min) {
        let message = "";
        if (prodItem.haveRetailL) {
          message = `请确认【${prodItem.model}】的零售价格。请在默认建议零售价的${prodItem.bei}倍范围内录入价格。`;
        } else {
          message = `请确认【${prodItem.model}】的零售价格。您录入的价格不符合产品公司规定，请按要求录入。`;
        }
        this.$message.warning(message);
        tempItem.salePrice = tempItem.retailPrice; // 输入价格不合理,赋值建议零售价
      }
      // }

      this.$set(this.productList, tempIndex, tempItem);
    },
    // 商品数量change
    prodNumChange(val, price, id) {
      console.log("商品数量change", val, price);
      const temp = this.productList;
      temp.forEach((i) => {
        if (i.id === id) {
          i.productOrderNum = val;
          i.subtotal = (Number(price) * val).toFixed(2);
        }
      });
      console.log("商品list", temp);
      this.productList = temp;
      // 查服务费
      this.getFWF();
    },
    // 获取销量所属门店
    getShopList() {
      let data = {
        serviceCode: "cisFxZsyhGetXlShop",
        code: this.$store.state.user.userInfo.customer.cisCode,
      };
      return ctsApi(data).then((res) => {
        console.log("获取销量所属门店", res.data);
        if (res.data.code == 0) {
          this.shopListOption = res.data.data.map((item) => {
            return {
              value: item.cisCode,
              label: item.fullName,
            };
          });
          if (res.data.data.length > 0) {
            // 默认选中第一项
            let firstItem = res.data.data[0];
            this.submitFormData.purchaseOrder.storeCode = firstItem.cisCode;
          }
          // res.data.data.forEach(el => {
          //   el.code = el.cisCode
          //   el.name = el.fullName
          //   el.selected = false
          // })
          // this.shopListOption = res.data.data
          // if(this.saleShopList.length>0){
          //   this.receiveShop = this.saleShopList[0].name;
          //   this.salesShopInfoId = this.saleShopList[0].code;
          //   let obj = this.saleShopList.find((i)=>{
          //     return i.code ===  this.saleShopList[0].code;//筛选出匹配数据
          //   });
          //   this.shopSelectId = obj.id
          // }
        }
      });
    },
    // 获取下拉的商家收货地址
    getAddressList() {
      this.pageLoadFlag = true;
      let data = {
        orgId: this.engineeringInfo.ORG_ID,
        matklId: this.matklId,
      };
      getAddress(data)
        .then((res) => {
          console.log("获取下拉的商家收货地址", res.data);
          if (res.data.code === 0) {
            this.shippingAddressOption = res.data.list;
            let firstItem = res.data.list[0];
            this.submitFormData.purchaseOrder.shippingAddress.value =
              firstItem.id;
            this.submitFormData.purchaseOrder.shippingAddress.key =
              firstItem.address;
          }

          // if (res.data.list.length > 0) {
          //   let obj = this.shippingAddress.find((i)=>{//listData就是上面的数据源
          //     return i.id === res.data.list[0].id;//筛选出匹配数据
          //   });
          //   // 如果选择的地址状态为D 说明地址失效 弹出提示
          //   if(obj.regionStatus == 'D') {
          //     this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
          //     this.receivehis = ''
          //     this.distributionAdd.DISCODE = ''
          //     this.officeCode = ''
          //     return
          //   }
          //   this.officeCode = res.data.list[0].id;
          //   this.receivehis = res.data.list[0].address;
          //   this.distributionAdd.DISCODE = res.data.list[0].id;
          // }

          // this.showAnotheer();
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
    // 根据收货地址id查收货人信息
    getContactInfo() {
      this.pageLoadFlag = true;
      getPhone({
        id: this.submitFormData.purchaseOrder.shippingAddress.value,
      })
        .then((res) => {
          console.log("根据收货地址id查收货人信息", res.data.list);
          if (res.data.list.length > 0) {
            this.contactList = res.data.list;
            // 联系人手机号默认第一项
            this.submitFormData.purchaseOrder.contact =
              res.data.list[0].contact;
            this.submitFormData.purchaseOrder.contactInfo =
              res.data.list[0].phone;
            // 处理联系人手机号下拉option
            this.contactOption = res.data.list.map((el) => {
              return {
                value: el.contact,
                label: el.contact,
              };
            });
            this.phoneOption = res.data.list.map((el) => {
              return {
                value: el.phone,
                label: el.phone,
              };
            });
          } else {
            this.submitFormData.purchaseOrder.contact = "";
            this.submitFormData.purchaseOrder.contactInfo = "";
          }
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
    // 联系人姓名change
    handleContactChange(value) {
      let target = this.contactList.find((el) => el.contact === value);
      this.submitFormData.purchaseOrder.contactInfo = target.phone;
    },
    // 手机号change
    changePhoneNum(value) {
      let target = this.contactList.find((el) => el.phone === value);
      this.submitFormData.purchaseOrder.contact = target.contact;
    },
    // 下拉收货地址change
    hishandleChangeAddress(val, option) {
      console.log("下拉收货地址change", val, option);
      this.submitFormData.purchaseOrder.shippingAddress.key = option.key;
    },
    // 获取自动完成详细地址
    getAdress(value) {
      if (!this.addressHx[2]) {
        this.$message.warning("请先选择省市区县");
        this.$refs.address.blur();
        this.userDetailAddress = "";
        return;
      }
      this.userDetailAddress = value;
      if (!this.userDetailAddress) {
        this.companyList = [];
        return;
      }
      if (
        this.userDetailAddress &&
        this.userDetailAddress.length < 4 &&
        this.userDetailAddress.length > 0
      ) {
        this.companyList = [];
        return;
      }
      let data = {
        searchstr: this.userDetailAddress,
        region: this.addressHx[2],
      };
      this.fetching = true;
      getAdressapi(data).then((res) => {
        if (
          res.data.data !== null &&
          res.data.data.pois &&
          res.data.data.pois.length > 0 &&
          res.data.data.pois !== null
        ) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList = [];
        }

        this.fetching = false;
      });
    },
    // 获取采购人员
    cglrywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "cglrywy",
      };
      return getSupplierS(data).then((res) => {
        var obj = JSON.parse(res.data);
        this.userid = Object.keys(obj.data[0])[0];
      });
    },
    // 查询服务费
    getFWF() {
      if (!["04", "08"].includes(this.giveType) || this.addressHx.length == 0) {
        return;
      }
      this.productLoadFlag = true;
      let data = {
        _loading: true,
        provinceCode: this.addressHx[0], // 省id
        cityCode: this.addressHx[1], // 市id
        countyCode: this.addressHx[2], // 区id
        townCode: this.addressHx[3], // 街道id
        warehouseInfoId: "", // 发货仓库id，分销商下单传空
        shopId: this.submitFormData.purchaseOrder.storeCode, // 销量所属门店id
        documentSource: 5,
        trans: this.giveType, // 配送方式
        orderServiceFeeItemDtoList: this.productList.map((el) => {
          return {
            zzprdmodel: el.model,
            productId: el.id,
            warehouseInfoId: "",
            qty: el.productOrderNum,
            orgId: this.engineeringInfo.ORG_ID,
          };
        }),
      };
      findMoneyBuyBegin(data)
        .then((res) => {
          const assignDefault = () => {
            this.totalFwfAmount = 0;
            this.productList = this.productList.map((productel) => {
              return {
                ...productel,
                serviceFee: 0,
              };
            });
          };
          //  如果接口没返回数据 就默认当前日期加3天
          this.startTime = this.$util.getDateSpecify(3);
          this.startShowTime = this.$util.getDateSpecify(3);
          console.log("查询服务费结果", res);
          if (res.data.code === 0) {
            let orderServiceFeeItemDtoList =
              res.data.data.orderServiceFeeItemDtoList || [];
            let totalFwfAmount = res.data.data.totalAmount || 0;
            // 总服务费
            this.totalFwfAmount = totalFwfAmount;
            // 取出每一项商品的服务费
            this.productList = this.productList.map((productel) => {
              let serviceFee =
                orderServiceFeeItemDtoList.find(
                  (el) => el.productId == productel.id
                ).serviceFee || 0;
              return {
                ...productel,
                serviceFee,
              };
            });
            // 处理期望送达时间
            if (res.data && res.data.data && res.data.data.timelinessDate) {
              this.startTime = res.data.data.timelinessDate
                ? res.data.data.timelinessDate.replace(/-/g, "/")
                : "";
              this.startShowTime = res.data.data.timelinessDate
                ? res.data.data.timelinessDate.replace(/-/g, "/")
                : "";
            } else {
              //  如果接口没返回数据 就默认当前日期加3天
              this.startTime = this.$util.getDateSpecify(3);
              this.startShowTime = this.$util.getDateSpecify(3);
            }
            console.log("查询服务费结果", this.productList, totalFwfAmount);
          } else {
            assignDefault();
          }
        })
        .finally(() => {
          this.productLoadFlag = false;
        });
    },
    // 根据销量所属门店和手机号查用户信息
    getUserByPhoneOrShop() {
      if (!this.submitFormData.purchaseOrder.contactInfo) return;
      let data = {
        phone: this.submitFormData.purchaseOrder.contactInfo,
        shopInfoId: this.submitFormData.purchaseOrder.storeCode,
      };

      getUserList(data).then((res) => {
        console.log("用户信息列表", res);
        // 如果用户标签为空则给默认新居 5007817
        const assignDefault = () => {
          this.TipsIds = [5007817];
          this.userTipsList = this.userTipsList.map((el) => {
            if (el.id === 5007817) {
              this.TipsNames = [el.label];
              return {
                ...el,
                checked: true,
              };
            }
            return {
              ...el,
              checked: false,
            };
          });
        };
        // 如果有用户标签赋值
        const assignTipsList = (newTipsId) => {
          this.TipsIds = newTipsId;
          this.userTipsList = this.userTipsList.map((el) => {
            if (newTipsId.includes(el.id)) {
              this.TipsNames = [el.label];
              return {
                ...el,
                checked: true,
              };
            }
            return {
              ...el,
              checked: false,
            };
          });
        };
        if (res.data.code !== 0 || !res.data.list.length) {
          // 如果响应码不为0或者列表为空，则直接调用assignDefault
          assignDefault();
          this.SorceIds = "5007684";
          return;
        }
        // 提取用户信息
        let userInfo = res.data.list[0];
        const labelIds = userInfo.userLableList.map((item) => item.labelId);
        const sourceId = userInfo.sourceId;
        console.log("用户标签id-list", labelIds);
        // 如果labelIds有内容则调用assignTipsList，否则调用assignDefault
        if (labelIds.length) {
          assignTipsList(labelIds);
        } else {
          assignDefault();
        }
        if (sourceId) {
          this.SorceIds = sourceId;
        } else {
          this.SorceIds = "5007684";
        }
      });
    },
    // 期望送达日期选择器聚焦事件
    arrivalTimeFocus() {
      if (!this.startTime && this.haveDeliveryFee === "1") {
        this.$message.warning("请先填选相关信息");
        return;
      } else {
        this.endOpen = true;
      }
    },
    // 禁用日期
    disabledDateS(current) {
      let three = moment(this.startShowTime);
      console.log("配送时间", current);
      return false;
      // return current < moment(this.startShowTime)
    },
    // 期望送货日期时间选择
    handleTimeChange(value, dateString) {
      this.startTime = dateString;
      this.endOpen = false;
    },
    // 日期选择器change
    handleEndOpenChange() {
      if (this.startTime) {
        this.endOpen = true;
        return;
      }
    },
    // 提交前入参验证
    checkParams() {
      const { purchaseOrder } = this.submitFormData;
      const checks = [
        [!this.matklId, "请选择物料组"],
        [!this.custId, "请选择供应商"],
        [!this.engineeringInfo, "请选择工程项目"],
        [
          ["02", "01", "07"].includes(this.giveType) &&
            !purchaseOrder.warehouseId,
          "请选择入库仓库",
        ],
        [
          ["07", "04", "08"].includes(this.giveType) &&
            !purchaseOrder.storeCode,
          "请选择销量所属门店",
        ],
        [
          ["07", "04", "08"].includes(this.giveType) &&
            this.addressHx.length === 0,
          "请选择省市区",
        ],
        [
          ["07", "04", "08"].includes(this.giveType) && !this.userDetailAddress,
          "请输入详细地址",
        ],
        [
          ["02", "01"].includes(this.giveType) &&
            !purchaseOrder.shippingAddress.value,
          "请选择收货地址",
        ],
        [
          !purchaseOrder.contact || !purchaseOrder.contactInfo,
          "请输入联系人信息",
        ],
        [
          ["04", "08"].includes(this.giveType) && !this.SorceIds,
          "请选择用户来源",
        ],
        [
          ["04", "08"].includes(this.giveType) && this.TipsIds.length === 0,
          "请选择用户标签",
        ],
        // 可以继续添加其他检查
      ];

      const failedCheck = checks.find(([condition]) => condition);
      if (failedCheck) {
        const [, message] = failedCheck;
        this.$message.warning(message);
        return false;
      }
      return true;
    },
    // 提交订单
    submitOrder() {
      if (this.pageLoadFlag || this.productLoadFlag) return;
      const next = this.checkParams();
      if (!next) return;
      const { purchaseOrder } = this.submitFormData;
      let cisAddressId = ''
      if(this.shippingAddressOption.length > 0 && this.giveType === '07'){
        cisAddressId = this.shippingAddressOption[0].id
      }
      if(!["04", "07", "08"].includes(this.giveType)){
        cisAddressId = purchaseOrder.shippingAddress.value
      }
      const submitParmas = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        purchaseOrder: {
          deliveryMode: this.giveType, // 配送方式
          warehouseId: purchaseOrder.warehouseId, // 入库仓库
          cisAddressId: cisAddressId, // 收货地址id
          address: !["04", "07", "08"].includes(this.giveType)
            ? purchaseOrder.shippingAddress.key
            : this.userDetailAddress, // 收货地址
          orgCode: this.engineeringInfo.ORG_ID || "", // 销售组织
          storeCode: purchaseOrder.storeCode, // 销量所属门店
          sourceId: this.SorceIds, // 用户来源
          customerLabels: this.TipsIds.join(","), // 用户标签
          provinceId: this.addressHx[0], // 省id
          cityId: this.addressHx[1], // 市id
          countryId: this.addressHx[2], // 区id
          townId: this.addressHx[3], // 街道id
          contact: purchaseOrder.contact, // 联系人
          contactInfo: purchaseOrder.contactInfo, // 联系方式
          gender: purchaseOrder.gender, // 性别
          supplierCode: this.submitFormData.purchaseOrder.supplierCode, // 代理id
          userId: this.userid, // 采购人id
          // edt: expectDocumentdata == '' ? '' : expectDocumentdata, // 期望送货日期
          message: purchaseOrder.message, // 订单备注
          documentDate: this.documentdata,
          engineeringCode: this.engineeringInfo.PROJECT_CODE,
          engineeringUnit: this.engineeringInfo.CUSTOMER_NAME,
          purchaseOrderItem: this.productList.map((el) => {
            return {
              ...el,
              productCode: el.id,
              orderedQty: el.productOrderNum,
              orgCode: this.engineeringInfo.ORG_ID,
              invStatusId: "",
              amount: Number(el.price) * el.productOrderNum,
              invStatusType: "",
            };
          }),
        },
      };
      this.pageLoadFlag = true;
      console.log("提交订单结果入参", submitParmas);
      let submitFunc = null;
      if (["04", "08"].includes(this.giveType)) {
        submitFunc = orderSubmit2C;
      } else {
        submitFunc = orderSubmit2;
      }
      const redirectTo = (path, query) => {
        this.$router.push({ path, query });
      };
      submitFunc(submitParmas)
        .then((res) => {
          let obj = JSON.parse(res.data);
          if (obj.code == "1") {
            this.$message.error(obj.msg);
          } else if (obj.code == "0") {
            const query = {
              b2bOrderCode: obj.msg,
              orgName: "订单号",
              channel: 1,
            };
            if (obj.deliveryOrderNum) {
              redirectTo("/serviceFeeModule/pay", {
                ...query,
                id: obj.deliveryOrderNum,
                batch: 0,
              });
              this.$router.push({
                path: "/serviceFeeModule/pay",
                query: {
                  id: obj.deliveryOrderNum,
                  batch: 0,
                  b2bOrderCode: obj.msg,
                  orgName: "订单号",
                  channel: 1,
                },
              });
              return;
            }
            this.$router.push({
              path: "/confirm/confirmsuccess",
              query: {
                b2bOrderCode: obj.msg,
                orgName: "订单号",
                channel: 1,
              },
            });
          }
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
  },
};
