// 配送方式
export const deliveryMethodOptions = [
  {
    propertyName: "配送",
    id: 0,
  },
  {
    propertyName: "自提",
    id: 1,
  },
  {
    propertyName: "直配工地",
    id: 2,
  },
  {
    propertyName: "直送用户",
    id: 3,
  },
  {
    propertyName: "送装一体",
    id: 4,
  },
];
// 面包屑
export const breadcrumbData = [
  {
    path: "/index",
    name: "index",
    title: "首页",
  },
  {
    path: "/engineeringPrcment",
    name: "工程采购",
    title: "工程采购",
  },
];

export const sexoptions = [
  { id: 1, name: "男士" },
  { id: 2, name: "女士" },
];

// 工程列表column
export const engineeringsCol = [
  {
    prop: "PROJECT_CODE",
    label: "工程编码",
    width: "120",
  },
  {
    prop: "CUSTOMER_NAME",
    label: "工程使用单位",
    width: "120",
  },
  {
    prop: "CUSTOMER_ADDR",
    label: "使用单位地址",
    width: "180",
  },
  {
    prop: "",
    label: "操作",
    width: "60",
  },
];

// 时间格式化
export const formatDate = (date, type = "Y-M-D h:m:s") => {
  let timestamp;
  let myDate;
  let hour;
  let time;
  if (date) {
    timestamp = parseInt(date, 10);
    if (timestamp < 10000) {
      timestamp = date;
    }
    if (typeof timestamp === "number") {
      myDate = new Date(timestamp);
    } else {
      myDate = new Date(timestamp.replace(/-/g, "/"));
    }
    hour = myDate.getHours();
  } else {
    myDate = new Date();
    hour = myDate.getHours();
  }
  const Y = myDate.getFullYear();
  const M =
    myDate.getMonth() + 1 < 10
      ? `0${myDate.getMonth() + 1}`
      : myDate.getMonth() + 1;
  const D = myDate.getDate() < 10 ? `0${myDate.getDate()}` : myDate.getDate();
  const h = hour < 10 ? `0${hour}` : hour;
  const m =
    myDate.getMinutes() < 10 ? `0${myDate.getMinutes()}` : myDate.getMinutes();
  const s =
    myDate.getSeconds() < 10 ? `0${myDate.getSeconds()}` : myDate.getSeconds();
  time = type.replace("Y", Y);
  time = time.replace("M", M);
  time = time.replace("D", D);
  time = time.replace("h", h);
  time = time.replace("m", m);
  time = time.replace("s", s);
  return time;
};

// 判断是否在当前列表
const includesFunc = function(key, list) {
  if (list && !list.indexOf) {
    return false;
  }
  return list ? list.indexOf(key) >= 0 : false;
};

// 判断是否免运费
export const isFreeShipping = function(list) {
  return includesFunc("15691143850", list);
};
